import React from 'react'
import { Card, Text } from 'theme-ui'
import Section from '@components/Section'
import Navigation from '@components/Navigation'
import useSiteMetadata from '@helpers/useSiteMetadata'
import attachSocialIcons from '@helpers/attachSocialIcons'

const Social = () => {
  const { social } = useSiteMetadata()

  return (
    <Section aside title="Let's Talk">
      <Card variant='paper'>
        <Text variant='p'>
          더 많은 이야기를 함께 나눠요! 깃허브와 홈페이지로 오세요!
        </Text>
        <Navigation items={attachSocialIcons(social)} iconOnly />
      </Card>
    </Section>
  )
}

export default Social
